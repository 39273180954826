import classNames from 'classnames';
import { TypoXXSBold } from '@atoms/Typos';
import COLORS from '@utils/colors';
import styles from './css';

type ColorProperty = '' | keyof typeof COLORS;

type Props = {
  color?: ColorProperty;
  text: string;
};

const getColorStyle = (colorKey?: ColorProperty) =>
  colorKey && colorKey.length > 0
    ? { backgroundColor: COLORS[colorKey] }
    : undefined;

const Label = ({ color = 'brown600', text }: Props) => (
  <div className={classNames('label')} style={{ ...getColorStyle(color) }}>
    <TypoXXSBold color="white" text={text} />
    <style jsx={true}>{styles}</style>
  </div>
);

export default Label;
