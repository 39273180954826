import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import IconCoin from '@assets/icon-coin-new.svg';
import Label from '@atoms/Label';
import PlanPrice from '@atoms/PlanPrice';
import SignUpPlanPrice from '@atoms/SignUpPlanPrice';
import { TypoLBold, TypoXSBold } from '@atoms/Typos';
import { useIsGacha20250306CampaignActive } from '@hooks/useCampaign';
import useRegistrationCountry from '@hooks/useRegistrationCountry';
import { NeighborPlan } from '@services/hafh/types/generated';
import {
  BASIC_CODE,
  NEW_STANDARD_CODE,
  TAIWAN_STANDARD_CODE,
} from '@utils/constants';
import { useIsJapanUser } from '@utils/hooks';
import { formatDiscountRate } from '@utils/utils';
import styles from './css';

type PlanInfoProps = {
  changeablePlan?: any;
  plan: NeighborPlan;
  showDiscount?: boolean;
  showLabel?: boolean;
  showReferralCodeBonus?: boolean;
};

const planLabel: { [key: string]: string } = {
  [BASIC_CODE]: '',
  [NEW_STANDARD_CODE]: '継続キャンペーン対象',
  [TAIWAN_STANDARD_CODE]: 'foo', // Note: This text has no meaning. It's just set to true.
};

// Note: Remove this code when the campaign is over.
const CAMPAIGN_DISCOUNT_RATE_FOR_JAPAN_REGION = '30%';

const PlanInfo = ({
  changeablePlan,
  plan,
  showDiscount,
  showLabel = true,
  showReferralCodeBonus = false,
}: PlanInfoProps) => {
  const signupPlanDiscount =
    showDiscount && plan.first_month_price < plan.monthly_price;
  const upgradePlanDiscount = changeablePlan && changeablePlan.discount;
  const noDiscount = !signupPlanDiscount && !upgradePlanDiscount;

  const isJapanUser = useIsJapanUser();
  const { isRegistrationCountryTaiwan } = useRegistrationCountry();

  const { t } = useTranslation('neighbor-plan');
  const discountRate = formatDiscountRate(
    changeablePlan
      ? 1 - changeablePlan.first_month_price / changeablePlan.monthly_plan_price
      : plan.discount?.rate
  );

  // Note: Remove this code when the campaign is over.
  const isGacha20250306CampaignActive = useIsGacha20250306CampaignActive();

  return (
    <div className="plan-info">
      {planLabel[plan.code] && showLabel && (
        <div className="plan-labels">
          {isJapanUser && (
            <Label color="green500" text={planLabel[plan.code]} />
          )}
          {signupPlanDiscount && (
            <Label
              color="pink500"
              text={t('firstMonthDiscount', {
                rate: isGacha20250306CampaignActive
                  ? CAMPAIGN_DISCOUNT_RATE_FOR_JAPAN_REGION
                  : discountRate,
              })}
            />
          )}
          {isGacha20250306CampaignActive && (
            <Label color="purple600" text="航空券ガチャチケット3回分" />
          )}
        </div>
      )}
      <div className="plan-promo">
        <TypoLBold text={plan.name} />
      </div>
      {signupPlanDiscount && (
        <SignUpPlanPrice
          isRegistrationCountryTaiwan={isRegistrationCountryTaiwan}
          plan={plan}
        />
      )}
      {upgradePlanDiscount && (
        <SignUpPlanPrice changeablePlan={changeablePlan} plan={plan} />
      )}
      {noDiscount && <PlanPrice plan={plan} />}
      <div className="coin-text-container">
        <div className="coin-text">
          <div className="coin-icon-cont">
            <IconCoin />
          </div>
          <TypoXSBold color="black900">
            <Trans
              i18nKey="neighbor-plan:coinPerMonth"
              values={{
                coin: plan.coin,
              }}
            />
          </TypoXSBold>
        </div>
        {showReferralCodeBonus && plan.referral_bonus_coin && (
          <div className="coin-text">
            <div className="coin-icon-cont">
              <IconCoin />
            </div>
            <TypoXSBold color="black600">
              <Trans
                components={{
                  black: <TypoXSBold color="black900" />,
                  sky: <TypoXSBold color="primary" />,
                }}
                i18nKey="neighbor-plan:referralCoin"
                values={{
                  coin: plan.referral_bonus_coin,
                }}
              />
            </TypoXSBold>
          </div>
        )}
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default PlanInfo;
