import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  .plan-info {
    background-color: ${COLORS.white};
    .plan-labels {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-bottom: 8px;
    }
    .plan-recommend {
      border-radius: 60px;
      width: fit-content;
      padding: 0 12px;
      background: ${COLORS.primary};
    }
    .plan-promo {
      display: flex;
      justify-content: space-between;
    }

    .coin-text-container {
      display: grid;
      gap: 4px;
      margin-top: 4px;
      .coin-text {
        display: flex;
        align-items: center;
        .coin-icon-cont {
          margin-right: 4px;
        }
      }
    }
  }
`;

export default styles;
