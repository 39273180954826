import Trans from 'next-translate/Trans';
import { TypoMBold, TypoXXLBold } from '@atoms/Typos';
import { NeighborPlan } from '@services/hafh/types/generated';
import styles from './css';

type Props = {
  changeablePlan?: any;
  isRegistrationCountryTaiwan?: boolean;
  plan: NeighborPlan;
};

const SignUpPlanPrice = ({
  changeablePlan,
  isRegistrationCountryTaiwan,
  plan,
}: Props) => (
  <div className="signup-price">
    <TypoMBold>
      <Trans
        components={{
          large: <TypoXXLBold color="pink500" />,
        }}
        i18nKey={
          isRegistrationCountryTaiwan
            ? 'neighbor-plan:pricePerMonthForTaiwanCampaign'
            : 'neighbor-plan:pricePerMonth'
        }
        values={{
          currencySymbol: plan.currency.symbol,
          price: (changeablePlan || plan).first_month_price.toLocaleString(),
        }}
      />
    </TypoMBold>
    {plan.first_month_price < plan.monthly_price && (
      <div className="normal-price">
        <Trans
          i18nKey={
            isRegistrationCountryTaiwan
              ? 'neighbor-plan:originalPriceForTaiwanCampaign'
              : 'neighbor-plan:originalPrice'
          }
          values={{
            currencySymbol: plan.currency.symbol,
            price: plan.monthly_price.toLocaleString(),
          }}
        />
      </div>
    )}
    <style jsx={true}>{styles}</style>
  </div>
);

export default SignUpPlanPrice;
