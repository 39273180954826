import css from 'styled-jsx/css';
import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .dialog-overlay {
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110; /* Needs to be higher than .header */
    .daialog-background {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 13;
    }
    .dialog-container {
      background-color: ${COLORS.white};
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
      text-align: center;
      margin: 12px;
      z-index: 20;
      width: 100%;
      max-width: 560px;
      max-height: 95%;
      overflow-y: scroll;
      &.warning {
        padding: 0;
      }

      .dialog-title {
        font-weight: bold;
        font-size: 28px;
        color: ${COLORS.black2};
        padding: 0 0 16px;
        white-space: pre-wrap;
      }
      .dialog-header,
      .room-unavailable-header {
        white-space: pre;
        font-weight: bold;
        color: ${COLORS.black2};

        &.with-border {
          border-bottom: 1px solid ${COLORS.black200};
        }
      }

      .dialog-header {
        font-size: 20px;
        padding: 32px 0 16px;
      }

      .room-unavailable-header {
        font-size: 16px;
        padding: 24px 0;

        @media screen and (max-width: ${BREAKPOINT.mobile}px) {
          padding: 16px 0;
        }
      }
      .dialog-content {
        :global(.survey-dialog-content) {
          padding: 32px 24px 0;
        }
      }
      .button-container {
        margin: 42px 16px;
        &.with-buttons {
          @media screen and (min-width: ${BREAKPOINT.desktop}px) {
            display: flex;
            flex-direction: row-reverse;
          }
        }
        :global(.btn) {
          margin-bottom: 16px;
          @media screen and (min-width: ${BREAKPOINT.desktop}px) {
            margin: 0 0 0 24px;
          }
        }
        .button-link {
          font-weight: bold;
          font-size: 14px;
          color: ${COLORS.primary};
          cursor: pointer;
          &.size-large {
            font-size: 16px;
          }
        }
        &.buttons-vertical {
          @media screen and (min-width: ${BREAKPOINT.desktop}px) {
            display: block;
          }
          :global(.btn) {
            width: 100%;
            margin: 16px 0;
          }
        }
      }
    }
    &.fullScreen {
      .dialog-container {
        margin: 0;
        padding-top: 56px;
        max-width: none;
        max-height: none;
        height: 100%;
        border-radius: 0;
        box-shadow: none;
        > * {
          max-width: 600px;
          margin-right: auto;
          margin-left: auto;
        }
        &.warning {
          padding: 0;
        }
        .dialog-title {
          @media screen and (min-width: ${BREAKPOINT.desktop}px) {
            padding: 0 0 32px;
          }
        }
        .button-container {
          padding-right: 16px;
          padding-left: 16px;
          &.with-buttons {
            @media screen and (min-width: ${BREAKPOINT.desktop}px) {
              display: block;
              margin-top: 48px;
              padding-right: 16px;
              padding-left: 16px;
            }
          }
          :global(.btn) {
            margin-bottom: 16px;
            @media screen and (min-width: ${BREAKPOINT.desktop}px) {
              width: 100%;
              max-width: 320px;
              margin: 0 auto 24px;
            }
          }
        }
      }
    }
  }
`;

export default styles;
