import classNames from 'classnames';
import useTranslation from 'next-translate/useTranslation';

import Button from '@atoms/Button';

import { useDisablePageScrollWhenShowModal } from '@utils/hooks';

import styles from './css';

type Props = {
  buttonAlign?: 'horizontal' | 'vertical';
  children: any;
  closeButtonSize?: string;
  closeLabel?: string;
  fullScreen?: boolean;
  headerClassName?: string;
  headerText?: string;
  modalType?: 'warning';
  onClickButton?: () => void;
  onClickPrimaryButton?: () => void;
  onClickSecondaryButton?: () => void;
  primaryButtonClass?: string;
  primaryButtonDisabled?: boolean;
  primaryButtonLabel?: string;
  primaryButtonSize?: string;
  secondaryButtonLabel?: string;
  secondaryButtonSize?: string;
  show: boolean;
  showActionButtons?: boolean;
  showCloseLabel?: boolean;
  showHeaderBorder?: boolean;
};

const Dialog = ({
  buttonAlign = 'horizontal',
  children,
  // Close button
  closeButtonSize,
  closeLabel,
  fullScreen,
  headerClassName = 'dialog-header',
  headerText,
  modalType,
  onClickButton,
  onClickPrimaryButton,
  onClickSecondaryButton,
  primaryButtonClass,
  primaryButtonDisabled = false,
  // Primary button
  primaryButtonLabel,
  primaryButtonSize,
  // Secondary button
  secondaryButtonLabel,
  secondaryButtonSize,
  show,
  showActionButtons = true,
  showCloseLabel = true,
  showHeaderBorder = false,
}: Props) => {
  const { t } = useTranslation('common');
  useDisablePageScrollWhenShowModal(show);

  const withPrimaryButton = primaryButtonLabel && onClickPrimaryButton;
  const withSecondaryButton = secondaryButtonLabel && onClickSecondaryButton;

  return show ? (
    <div
      className={classNames('dialog-overlay', {
        active: show,
        fullScreen,
      })}
    >
      <div className={`dialog-container ${modalType ? modalType : ''}`}>
        {headerText &&
          (fullScreen ? (
            <h2 className={classNames('dialog-title')}>{headerText}</h2>
          ) : (
            <h3
              className={classNames(headerClassName, {
                'with-border': showHeaderBorder,
              })}
            >
              {headerText}
            </h3>
          ))}
        <div className="dialog-content">{children}</div>
        {showActionButtons && (
          <div
            className={classNames('button-container', {
              'buttons-vertical': buttonAlign === 'vertical',
              'with-buttons': withPrimaryButton || withSecondaryButton,
            })}
          >
            {withPrimaryButton && (
              <Button
                customClass={classNames('primary-button', primaryButtonClass)}
                disabled={primaryButtonDisabled}
                label={primaryButtonLabel}
                size={primaryButtonSize}
                testId="dialog-primary-button"
                width="responsive"
                onClick={(e) => {
                  e.stopPropagation();
                  onClickPrimaryButton();
                }}
              />
            )}
            {withSecondaryButton && (
              <Button
                isSecondary
                customClass="Secondary-button"
                label={secondaryButtonLabel}
                size={secondaryButtonSize}
                width="responsive"
                onClick={(e) => {
                  e.stopPropagation();
                  onClickSecondaryButton();
                }}
              />
            )}
            {onClickButton && showCloseLabel && (
              <button
                className={classNames('button-link', {
                  [`size-${closeButtonSize}`]: closeButtonSize,
                })}
                data-testid="close-dialog"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  onClickButton();
                }}
              >
                {closeLabel || t('close')}
              </button>
            )}
          </div>
        )}
      </div>
      <div
        aria-hidden
        className="daialog-background"
        role="button"
        onClick={onClickButton}
      />

      <style jsx>{styles}</style>
    </div>
  ) : null;
};

export default Dialog;
